import React, { useState, useEffect, Suspense } from "react";
//import { API, graphqlOperation } from "aws-amplify";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsconfig from "./aws-exports";
//import { Button } from "@material-ui/core";
import { withAuthenticator } from "@aws-amplify/ui-react";

import MySignIn from "./components/Signin/Login";
import Signin from "./components/Signin/Login";
import drawer from "./components/layouts/drawer";
import "@aws-amplify/ui-react/styles.css";
Amplify.configure(awsconfig);

// Preloader
//const Preloader = React.lazy(() => import("./components/layouts/Preloader"));
// Dashboard
const Home = React.lazy(() => import("./components/pages/Home"));
const ToDo = React.lazy(() => import("./components/pages/todo"));
const SignUp = React.lazy(() => import("./components/pages/SignUp"));
const ToDoList = React.lazy(() => import("./components/list"));

const Welcome = React.lazy(() => import("./components/pages/Welcome"));
const AdminPanel = React.lazy(() => import("./components/pages/Admin"));
const Webanalytics = React.lazy(() =>
	import("./components/pages/dashboard/Webanalytics")
);
const Socialmedia = React.lazy(() =>
	import("./components/pages/dashboard/Socialmedia")
);
const Projectmanagement = React.lazy(() =>
	import("./components/pages/dashboard/Projectmanagement")
);
const Clientmanagement = React.lazy(() =>
	import("./components/pages/dashboard/Clientmanagement")
);
// Order Page
const Orderstatus = React.lazy(() =>
	import("./components/pages/order/Orderstatus")
);
// Product Page
const Productlist = React.lazy(() =>
	import("./components/pages/product/Productlist")
);
const Productgrid = React.lazy(() =>
	import("./components/pages/product/Productgrid")
);
const Productdetail = React.lazy(() =>
	import("./components/pages/product/Productdetail")
);
const Cancelproduct = React.lazy(() =>
	import("./components/pages/product/Cancelproduct")
);
const Returnproduct = React.lazy(() =>
	import("./components/pages/product/Returnproduct")
);
// Customer Page
const Customer = React.lazy(() =>
	import("./components/pages/customer/Customer")
);
const Reviews = React.lazy(() => import("./components/pages/customer/Reviews"));
// Invoice
const Invoice = React.lazy(() => import("./components/pages/invoice/Invoice"));
const Invoicelist = React.lazy(() =>
	import("./components/pages/invoice/Invoicelist")
);
// Add product
const AddProduct = React.lazy(() => import("./components/pages/Addproduct"));
// Pricing
const Pricing = React.lazy(() => import("./components/pages/Pricing"));
// Shipment
const Shipment = React.lazy(() => import("./components/pages/Shipment"));
// Widgets
const Widgets = React.lazy(() => import("./components/pages/Widgets"));
// Basic UI Elements
const Accordions = React.lazy(() =>
	import("./components/pages/ui-basic/Accordions")
);
const Alerts = React.lazy(() => import("./components/pages/ui-basic/Alerts"));
const Buttons = React.lazy(() => import("./components/pages/ui-basic/Buttons"));
const Breadcrumbs = React.lazy(() =>
	import("./components/pages/ui-basic/Breadcrumbs")
);
const Badges = React.lazy(() => import("./components/pages/ui-basic/Badges"));
const Cards = React.lazy(() => import("./components/pages/ui-basic/Cards"));
const Progressbar = React.lazy(() =>
	import("./components/pages/ui-basic/Progressbar")
);
const Preloaders = React.lazy(() =>
	import("./components/pages/ui-basic/Preloaders")
);
const Pagination = React.lazy(() =>
	import("./components/pages/ui-basic/Pagination")
);
const Tabs = React.lazy(() => import("./components/pages/ui-basic/Tabs"));
const Typography = React.lazy(() =>
	import("./components/pages/ui-basic/Typography")
);
// Advanced UI Elements
const Draggables = React.lazy(() =>
	import("./components/pages/ui-advanced/Draggables")
);
const Sliders = React.lazy(() =>
	import("./components/pages/ui-advanced/Sliders")
);
const Modals = React.lazy(() =>
	import("./components/pages/ui-advanced/Modals")
);
const Rating = React.lazy(() =>
	import("./components/pages/ui-advanced/Rating")
);
const Tour = React.lazy(() => import("./components/pages/ui-advanced/Tour"));
const Cropper = React.lazy(() =>
	import("./components/pages/ui-advanced/Cropper")
);
const Rangeslider = React.lazy(() =>
	import("./components/pages/ui-advanced/Rangeslider")
);
// Animations
const Animation = React.lazy(() => import("./components/pages/Animation"));
// Form Elements
const Formelements = React.lazy(() =>
	import("./components/pages/form/Formelements")
);
const Formlayout = React.lazy(() =>
	import("./components/pages/form/Formlayout")
);
const Formvalidation = React.lazy(() =>
	import("./components/pages/form/Formvalidation")
);
const Formwizard = React.lazy(() =>
	import("./components/pages/form/Formwizard")
);
// Charts
const Chartjs = React.lazy(() => import("./components/pages/charts/Chartjs"));
const Googlecharts = React.lazy(() =>
	import("./components/pages/charts/Googlecharts")
);
// Tables
const Basictables = React.lazy(() =>
	import("./components/pages/tables/Basictables")
);
const Datatables = React.lazy(() =>
	import("./components/pages/tables/Datatables")
);
// Popups
const Sweetalerts = React.lazy(() =>
	import("./components/pages/popups/Sweetalerts")
);
const Toast = React.lazy(() => import("./components/pages/popups/Toast"));
// Icons
const Fontawesome = React.lazy(() =>
	import("./components/pages/icons/Fontawesome")
);
const Flaticons = React.lazy(() =>
	import("./components/pages/icons/Flaticons")
);
const Materialize = React.lazy(() =>
	import("./components/pages/icons/Materialize")
);
// Maps
const Googlemaps = React.lazy(() =>
	import("./components/pages/maps/Googlemaps")
);
//const Vectormaps = React.lazy(() =>import("./components/pages/maps/Vectormaps"));
// Pages
//const Defaultlogin = React.lazy(() =>	import("./components/pages/prebuilt-pages/Defaultlogin"));

const Lockscreen = React.lazy(() =>
	import("./components/pages/prebuilt-pages/Lockscreen")
);
const Comingsoon = React.lazy(() =>
	import("./components/pages/prebuilt-pages/Comingsoon")
);
const Error = React.lazy(() =>
	import("./components/pages/prebuilt-pages/Error")
);
const Faq = React.lazy(() => import("./components/pages/prebuilt-pages/Faq"));
const Portfolio = React.lazy(() =>
	import("./components/pages/prebuilt-pages/Portfolio")
);
const Userprofile = React.lazy(() =>
	import("./components/pages/prebuilt-pages/Userprofile")
);
const Pageinvoice = React.lazy(() =>
	import("./components/pages/prebuilt-pages/Invoice")
);
// Apps
const Chat = React.lazy(() => import("./components/pages/apps/Chat"));
const Email = React.lazy(() => import("./components/pages/apps/Email"));
const Todolist = React.lazy(() => import("./components/pages/apps/Todolist"));

function App() {
	const [loggedIn, setLoggedIn] = useState(false);
	// const [currentUser, setCurrentUser] = useState(false);
	const assessLoggedInState = () => {
		Auth.currentAuthenticatedUser()
			.then((sess) => {
				console.log("logged in", sess,loggedIn);
				setLoggedIn(true);
			})
			.catch(() => {
				console.log("not logged in");
				setLoggedIn(false);
			});
	};
	useEffect(() => {
		assessLoggedInState();
	}, []);
	// this function is not being used for the time being and is gernating a warning
	const SignOut = async () => {
		try {
			await Auth.signOut();
			setLoggedIn(false);
		} catch (error) {
			console.log("error signing out: ", error);
		}
	};

	Hub.listen("auth", (data) => {
		const event = data.payload.event;
		console.log("event:", event);
		if (event === "signOut") {
			console.log("user signed out...");
		}
	});

	return (
		<Router>
			<Suspense fallback={<div></div>}>
				<Switch>
					{/* Dashboard */}
					<Route exact path="/" component={Welcome} />
					<Route exact path="/Home" component={Home} />
					<Route path="/dashboard/web-analytics" component={Webanalytics} />
					<Route path="/drawer" component={drawer} />
					<Route path="/Admin" component={AdminPanel} />
					<Route path="/Signin" component={Signin} />
					<Route path="/ToDo" component={ToDo} />
					<Route path="/Signup" component={SignUp} />
					<Route path="/Listtodo" component={ToDoList} />

					<Route path="/dashboard/social-media" component={Socialmedia} />
					<Route
						path="/dashboard/project-management"
						component={Projectmanagement}
					/>
					<Route
						path="/dashboard/client-management"
						component={Clientmanagement}
					/>
					{/* Order Page */}
					<Route path="/order/order-status" component={Orderstatus} />
					{/* Product Page */}
					<Route path="/product/product-list" component={Productlist} />
					<Route path="/product/product-grid" component={Productgrid} />
					<Route path="/product/product-detail/:id" component={Productdetail} />
					<Route path="/product/cancel-product" component={Cancelproduct} />
					<Route path="/product/return-product" component={Returnproduct} />
					{/* Customer Page */}
					<Route path="/customer/customer" component={Customer} />
					<Route path="/customer/reviews" component={Reviews} />
					{/* Invoice */}
					<Route path="/invoice/invoice" component={Invoice} />
					<Route path="/invoice/invoice-list" component={Invoicelist} />
					{/* Add product */}
					<Route path="/add-product" component={AddProduct} />
					{/* Pricing */}
					<Route path="/pricing" component={Pricing} />
					{/* Shipment */}
					<Route path="/shipment" component={Shipment} />
					{/* Widgets */}
					<Route path="/widgets" component={Widgets} />
					{/* Basic UI Elements */}
					<Route path="/ui-basic/accordions" component={Accordions} />
					<Route path="/ui-basic/alerts" component={Alerts} />
					<Route path="/ui-basic/buttons" component={Buttons} />
					<Route path="/ui-basic/breadcrumbs" component={Breadcrumbs} />
					<Route path="/ui-basic/badges" component={Badges} />
					<Route path="/ui-basic/cards" component={Cards} />
					<Route path="/ui-basic/progress-bars" component={Progressbar} />
					<Route path="/ui-basic/preloaders" component={Preloaders} />
					<Route path="/ui-basic/pagination" component={Pagination} />
					<Route path="/ui-basic/tabs" component={Tabs} />
					<Route path="/ui-basic/typography" component={Typography} />
					{/* Advanced UI Elements */}
					<Route path="/ui-advanced/draggables" component={Draggables} />
					<Route path="/ui-advanced/sliders" component={Sliders} />
					<Route path="/ui-advanced/modals" component={Modals} />
					<Route path="/ui-advanced/rating" component={Rating} />
					<Route path="/ui-advanced/tour" component={Tour} />
					<Route path="/ui-advanced/cropper" component={Cropper} />
					<Route path="/ui-advanced/range-slider" component={Rangeslider} />
					{/* Animations */}
					<Route path="/animation" component={Animation} />
					{/* Form Elements */}
					<Route path="/form/form-elements" component={Formelements} />
					<Route path="/form/form-layout" component={Formlayout} />
					<Route path="/form/form-validation" component={Formvalidation} />
					<Route path="/form/form-wizard" component={Formwizard} />
					{/* Charts */}
					<Route path="/charts/chartjs" component={Chartjs} />
					<Route path="/charts/google-charts" component={Googlecharts} />
					{/* Tables */}
					<Route path="/tables/basic-tables" component={Basictables} />
					<Route path="/tables/data-tables" component={Datatables} />
					{/* Popups */}
					<Route path="/popups/sweet-alerts" component={Sweetalerts} />
					<Route path="/popups/toast" component={Toast} />
					{/* Icons */}
					<Route path="/icons/fontawesome" component={Fontawesome} />
					<Route path="/icons/flaticons" component={Flaticons} />
					<Route path="/icons/materialize" component={Materialize} />
					{/* Maps */}
					<Route path="/maps/google-maps" component={Googlemaps} />
					{/* Pages */}

					<Route path="/lock-screen" component={Lockscreen} />
					<Route path="/coming-soon" component={Comingsoon} />

					<Route path="/Faq" component={Faq} />
					<Route path="/prebuilt-pages/portfolio" component={Portfolio} />
					<Route path="/user-profile" component={Userprofile} />
					<Route path="/prebuilt-pages/invoice" component={Pageinvoice} />
					{/* Apps */}
					<Route path="/apps/chat" component={Chat} />
					<Route path="/apps/email" component={Email} />
					<Route path="/apps/to-do-list" component={Todolist} />
					<Route path="/*" component={Error} />
				</Switch>
			</Suspense>
		</Router>
	);
}

//export default App;

//export default withAuthenticator(App, { initialAuthState: "signin" });
export default withAuthenticator(App, false, [<MySignIn />]);
