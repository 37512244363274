import {
	Authenticator,
	Flex,
	Grid,
	Image,
	useTheme,
	View,
} from "@aws-amplify/ui-react";
import App from "../../App";

import { Header } from "./Header";
import { Footer } from "./Footer";
import { SignInHeader } from "./SignInHeader";
import { SignInFooter } from "./SignInFooter";

const components = {
	Header,
	SignIn: {
		Header: SignInHeader,
		Footer: SignInFooter,
	},
	Footer,
};

function Signin() {
	const { tokens } = useTheme();

	return (
		<Grid templateColumns={{ base: "1fr 0", medium: "1fr 1fr" }}>
			<Flex
				backgroundColor={tokens.colors.background.secondary}
				justifyContent="center"
			>
				<Authenticator components={components}>
					{({ SignOut, user }) => (
						<main>
							<App />
						</main>
					)}
				</Authenticator>
			</Flex>
			<View height="100vh">
				<Image
					src="../../assets/img/others/dobbie2.png"
					width="100%"
					height="100%"
					objectFit="cover"
				/>
			</View>
		</Grid>
	);
}
export default Signin;
